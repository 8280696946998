html {
  scroll-behavior: smooth;
}

/* font-family: 'Regulator'; */

/* font-family: 'optimaregular'; */

@media screen and (max-width: 1366px) {
  .container {
    max-width: 1366px;
  }
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  font-weight: normal;
  font-style: normal;
}

.background-bg {
  background: url(../images/background-image.jpg);
  background-size: cover;
  height: 100vh;
}
.videoTag {
  position: relative !important;
  z-index: 999 !important;
  width: 100% !important;
}

.curve-box-signup {
  background: url(../images/curvebgsignup.png);
  background-size: 100% 100% !important;
  width: 100% !important;
  height: 625px;
  background-repeat: no-repeat;
  position: relative;
  /* backdrop-filter: blur(7px);
    border-radius: 29%; */
  z-index: 0;
}
.curve-box-signin {
  background: url(../images/curvebgsignup.png);
  background-size: 100% 100% !important;
  width: 100% !important;
  height: 625px;
  background-repeat: no-repeat;
  position: relative;
  /* backdrop-filter: blur(7px);
    border-radius: 29%; */
  z-index: 0;
}
.signup-logo-container {
  display: flex;
  justify-content: center;
}
.logo-signups {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .signup-logo{
    margin-right: 5%;
} */

.w100 {
  width: 100%;
}
.for-margin {
  padding-right: -0px !important;
  padding-left: -0px !important;
}
.css-1s2u09g-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}

.curve-box-signup::after {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: url(../images/bg-signup.png);
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}
.curve-box-signin::after {
  position: absolute;
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: url(../images/bg-login.png);
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}

.padding-lefts {
  padding-left: 50px;
}

.formsignup h2 {
  font-size: 22px;
  color: #fff;
  font-family: "optimaregular";
}

.formsignup a {
  color: #2f3150;
  font-size: 15px;
  display: inline-block;
  margin-bottom: 15px;
  font-family: "optimaregular";
  font-weight: 600;
  margin-top: 10px;
  text-decoration: none;
}

.formsignup input {
  background: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding: 12px 0 12px 0;
  border-bottom: 1px dotted #fff;
  box-shadow: none !important;
  color: #fff !important;
  font-size: 24px !important;
  font-family: "Regulator";
}
.formsignup select {
  background: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding: 12px 0 12px 0;
  border-bottom: 1px dotted #fff;
  box-shadow: none !important;
  color: #fff !important;
  font-size: 24px !important;
  font-family: "Regulator";
}

.formsignup select {
  background: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding: 12px 0 12px 0;
  border-bottom: 1px dotted #fff;
  box-shadow: none !important;
  color: #fff !important;
  font-size: 24px !important;
  font-family: "Regulator";
}
.formsignup select option {
  color: #000 !important;
}

.formsignup select:focus-visible {
  outline-offset: 0px !important;
  outline: 0 !important;
  border-bottom: 1px dotted #fff !important;
}
.form-control:focus {
  border-color: #fff;
}
.formsignup ::-webkit-input-placeholder {
  /* Edge */
  color: #fff !important;
}

.formsignup :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff !important;
}

.formsignup ::placeholder {
  color: #fff !important;
}

.formsignup .form-control:focus {
  background: transparent !important;
}

.formsignup input:focus-visible {
  outline-offset: 0px !important;
  outline: 0 !important;
  border-bottom: 1px dotted #fff !important;
}

.formsignup .form-group {
  color: #fff !important;
}

a.btn-submit {
  border-radius: 50px !important;
  padding: 4px 25px;
  font-size: 18px !important;
  font-family: "Regulator";
}

.btncolor {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e39684+0,f4c3b8+99 */
  background: #e39684;
  /* Old browsers */
  background: -moz-linear-gradient(top, #e39684 0%, #f4c3b8 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #e39684 0%, #f4c3b8 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #e39684 0%, #f4c3b8 99%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#e39684', endColorstr='#f4c3b8', GradientType=0);
  /* IE6-9 */
  border-bottom: 0;
  box-shadow: none !important;
}

.image-rounds {
  position: absolute;
  top: 50px;
  left: 50px;
}

.mute-rounds {
  position: absolute;
  top: initial !important;
  left: 50px;
  bottom: 50px;
}

.mute-rounds img {
  margin-right: 10px;
}

.mute-rounds a {
  color: #fff;
  margin-bottom: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  font-family: "Regulator";
  font-size: 18px;
  text-decoration: none;
}

.close-btn {
  position: absolute;
  right: 0;
  top: 0;
}

.logo-mobile {
  display: none;
}

/* signupend */

.backgroundgrey {
  background: #86849b;
}
.backgroundgreytwo {
  background: transparent;
}

.menulogo a {
  font-size: 18px;
  color: #fff;
  text-decoration: none;
}

.menulogo a img {
  margin-right: 10px;
}

.btn {
  border-radius: 50px !important;
  padding: 4px 25px !important;
  font-size: 18px !important;
  font-family: "Regulator";
}

.white-border {
  border: 1px solid #fff !important;
  color: #fff !important;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.loginbtns .btn:hover {
  color: #fff;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e39684+0,f4c3b8+99 */
  background: #e39684;
  /* Old browsers */
  background: -moz-linear-gradient(top, #e39684 0%, #f4c3b8 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #e39684 0%, #f4c3b8 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #e39684 0%, #f4c3b8 99%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#e39684', endColorstr='#f4c3b8', GradientType=0);
  /* IE6-9 */
}

.loginbtns-search .btn:hover {
  color: #fff;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e39684+0,f4c3b8+99 */
  background: #e39684;
  /* Old browsers */
  background: -moz-linear-gradient(top, #e39684 0%, #f4c3b8 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #e39684 0%, #f4c3b8 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #e39684 0%, #f4c3b8 99%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#e39684', endColorstr='#f4c3b8', GradientType=0);
  /* IE6-9 */
}

.spacerow {
  padding: 20px 0;
}

.mr-1-desk {
  margin-right: 10px;
}

.loginbtns {
  text-align: right;
}
.loginbtns-search {
  text-align: center;
}

.heading-para p {
  font-size: 18px;
  color: #fff;
  font-family: "optimaregular";
  position: relative;
  width: 90%;
  padding-right: 20px;
}

.colorhref {
  color: #e39684;
}

.bottomhrefs {
  background: #716f88;
  text-align: left;
  padding: 5px 4px 5px 18px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.bottomhrefs a {
  font-size: 14px;
  margin: 0px auto;
  text-align: center;
  line-height: 0;
  display: inline-block;
  text-decoration: none;
}

.bottomhrefs a:hover {
  color: #fff;
}
a:hover {
  color: #e8a393;
}

.gray-lightdark {
  background: #7c7a91;
}

.heading-logo p {
  margin-bottom: 0;
  font-size: 13px;
  color: #fff;
  margin-top: 8px;
  margin-bottom: 15px;
}

.heading-logo {
  padding: 18px;
}

.heading-para p::after {
  position: absolute;
  content: "";
  display: block;
  background: #fff;
  height: 1px;
  width: 40px;
  bottom: -4px;
}

.menusearchs input {
  width: 100%;
  border-radius: 50px;
  border: 0;
  height: 32px;
  font-size: 13px;
  padding: 0 8px;
  position: relative;
  color: #86849b;
}

.searchicon {
  position: absolute;
  z-index: 9;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.searchicon img {
  width: 18px;
  height: 18px;
}

.menusearchs {
  position: relative;
}

.menusearchs :focus-visible {
  outline: none !important;
  box-shadow: none !important;
}

.box-bottom-href {
  min-height: 180px;
  position: relative;
}

.mindscapebg {
  background: url(../images/mindscapebg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.mmlogo img {
  width: 150px;
}

.bgnone {
  background: transparent !important;
}

.menu-navs ul {
  padding-left: 0;
  list-style: none;
}

.menu-navs {
  padding-left: 18px;
}

.menu-navs ul li a {
  color: #fff;
  font-family: "Regulator";
  font-size: 20px;
  text-decoration: none;
}

.menu-navs ul li a:hover {
  color: #e39684;
}

.profilename-d img {
  width: 100px;
  margin: 0px auto;
  display: block;
}

.profilename-d h3 {
  font-size: 20px;
  color: #fff;
  text-align: center;
  margin: 8px 0 0 0;
  font-family: "Regulator";
}

.profilename-d h5 {
  font-size: 18px;
  color: #2f3150;
  text-align: center;
  margin: 0px 0 0 0;
  font-family: "Regulator";
}

.splashtext h3 {
  font-size: 24px;
  color: #fff;
  font-family: "Regulator";
  margin-bottom: 0;
}

.splashtext p {
  font-size: 18px;
  color: #fff;
  font-family: "Regulator";
}

.toprightsearch {
  position: absolute;
  right: 50px;
  top: 50px;
  text-align: center;
}

.toprightsearch .menusearchs input {
  background: rgb(148, 146, 174);
  background: linear-gradient(
    90deg,
    rgba(148, 146, 174, 0.4514180672268907) 0%,
    rgba(74, 73, 87, 0.3533788515406162) 100%
  );
  color: #fff !important;
  padding: 0 35px 0 20px;
}

.toprightsearch .searchicon img {
  filter: brightness(0) invert(1);
}

.toprightsearch .menusearchs input::-webkit-input-placeholder {
  /* Edge */
  color: #fff !important;
}

.toprightsearch .menusearchs input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff !important;
}

.toprightsearch .menusearchs input::placeholder {
  color: #fff !important;
}

.curve-box-signup.selecttabs {
  background: url(../images/selectbg.png);
  background-size: 100% 100%;
}
.curve-box-signin.selecttabs {
  background: url(../images/selectbg.png);
  background-size: 100% 100%;
}

.curve-box-signup.selecttabs::after {
  display: none;
}

.curve-box-signin.selecttabs::after {
  display: none;
}
.select-button a {
  background: rgb(148, 146, 174);
  background: linear-gradient(
    180deg,
    rgba(148, 146, 174, 0.3533788515406162) 0%,
    rgba(133, 131, 159, 0.23012955182072825) 100%
  );
  display: inline-block;
  cursor: pointer;
  padding: 10px;
  border-radius: 50px;
  width: 100%;
  text-align: center;
  font-size: 24px;
  color: #fff;
  font-family: "Regulator";
  text-decoration: none;
}
.select-button a:hover {
  background: #e39684;
  /* Old browsers */
  background: -moz-linear-gradient(top, #e39684 0%, #f4c3b8 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #e39684 0%, #f4c3b8 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #e39684 0%, #f4c3b8 99%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#e39684', endColorstr='#f4c3b8', GradientType=0);
  /* IE6-9 */
  border-bottom: 0;
  box-shadow: none !important;
  color: #fff;
}
.select-button a:active {
  background: #e39684;
  /* Old browsers */
  background: -moz-linear-gradient(top, #e39684 0%, #f4c3b8 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #e39684 0%, #f4c3b8 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #e39684 0%, #f4c3b8 99%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#e39684', endColorstr='#f4c3b8', GradientType=0);
  /* IE6-9 */
  border-bottom: 0;
  box-shadow: none !important;
  color: #fff;
}

.headingwithlogo {
  text-align: center;
}

.headingwithlogo h3 {
  font-size: 22px;
  color: #e39684;
  margin: 40px 0 40px 0;
  font-family: "Regulator";
}

.select-button {
  margin-bottom: 10px;
}

.nextbtn {
  position: absolute;
  right: 40px;
  width: 50px;
  cursor: pointer;
  transition: 0.3s;
}
.nextbtn:hover {
  transform: scale(1.1);
}

.nextbtn img {
  width: 100%;
}

.rightbtn {
  position: absolute;
  left: 40px;
  width: 50px;
}

.rightbtn img {
  width: 100%;
}

a.selectstyle.active {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e39684+0,f4c3b8+99 */
  background: #e39684;
  /* Old browsers */
  background: -moz-linear-gradient(top, #e39684 0%, #f4c3b8 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #e39684 0%, #f4c3b8 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #e39684 0%, #f4c3b8 99%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#e39684', endColorstr='#f4c3b8', GradientType=0);
  /* IE6-9 */
  border-bottom: 0;
  box-shadow: none !important;
}

.dot-style {
  position: absolute;
  left: 48.5%;
  transform: translateX(-50%);
  bottom: 20px;
}

.dot-style span {
  display: inline-block;
  width: 4px;
  height: 4px;
  background: #fff;
  margin: 0 5px;
  border-radius: 50%;
}

.mt-40c {
  margin-top: 40px;
}

.question h5 {
  color: #fff;
  font-size: 24px;
  text-align: center;
  margin-top: 10px;
  font-family: "Regulator";
}

.inputstyles input {
  background: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding: 12px 0 12px 0;
  border-bottom: 1px dotted #ffffff9c;
  box-shadow: none !important;
  color: #fff !important;
  font-size: 24px !important;
  font-family: "Regulator";
  text-align: center;
}

.inputstyles ::-webkit-input-placeholder {
  /* Edge */
  color: #ffffff9c !important;
}

.inputstyles :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff9c !important;
}

.inputstyles ::placeholder {
  color: #ffffff9c !important;
}

.inputstyles .form-control:focus {
  background: transparent !important;
}

.inputstyles input:focus-visible {
  outline-offset: 0px !important;
  outline: 0 !important;
  border-bottom: 1px dotted #fff !important;
}

.inputstyles .form-group {
  color: #fff !important;
}

.mmautos button {
  margin: 0px auto;
  display: inherit;
}

.lh a {
  line-height: 1;
}

.select-button select:focus-visible {
  outline-offset: 0px;
  outline: 0 !important;
  border: 0 !important;
}
.multiselect:focus-visible {
  outline-offset: 0px;

  outline: 0 !important;
  border: 0 !important;
}
.multiselect {
  appearance: none;
  border: 0;
  width: 100%;
  background: rgb(148, 146, 174) !important;
  background: linear-gradient(
    180deg,
    rgba(148, 146, 174, 0.3533788515406162) 0%,
    rgba(133, 131, 159, 0.23012955182072825) 100%
  ) !important;
  display: inline-block;
  padding: 10px 10px 10px 20px;
  border-radius: 50px;
  width: 100%;
  text-align: left;
  font-size: 24px;
  color: #000;
  font-family: "Regulator";
  text-decoration: none;
  position: relative;
}
.multiselect::-ms-expand {
  display: none;
}

.multiselect option {
  background: rgb(148 146 174) !important;
  display: inline-block;
}

.select-button select {
  appearance: none;
  border: 0;
  width: 100%;
  background: rgb(148, 146, 174) !important;
  background: linear-gradient(
    180deg,
    rgba(148, 146, 174, 0.3533788515406162) 0%,
    rgba(133, 131, 159, 0.23012955182072825) 100%
  ) !important;
  display: inline-block;
  padding: 10px 10px 10px 20px;
  border-radius: 50px;
  width: 100%;
  text-align: left;
  font-size: 24px;
  color: #fff;
  font-family: "Regulator";
  text-decoration: none;
  position: relative;
}

.roundiconsselect {
  position: absolute;
  height: 20px;
  right: 0;
  transform: translateY(-50%);
  top: 50%;
}

.roundiconsselect::after {
  /* background-image: url(../images/bottom-select.png); */
  width: 20px;
  height: 15px;
  display: block;
  content: "";
  position: absolute;
  background-size: 100%;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  background-repeat: no-repeat !important;
}

.select-button select::-ms-expand {
  display: none;
}

.select-button select > option {
  background: rgb(148 146 174) !important;
  display: inline-block;
}

.select-button option:hover {
  background-color: #515473 !important;
}

.curve-box-signup.afteroff::after {
  display: none !important;
}

.curve-box-signup.afteroff {
  background: url(../images/curvewhite.png);
}
.curve-box-signin.afteroff {
  background: url(../images/curvewhite.png);
}

.headingwithlogo h6 {
  font-size: 17px;
  margin-bottom: 0;
  font-family: "optimaregular";
  color: #2f3150;
}

.colornew {
  color: #f7ab99 !important;
}

.boximagebox img {
  width: 100%;
}

.boximagebox {
  text-align: center;
}

.boximagecont h3 {
  font-size: 24px;
  color: #fff;
  font-family: "Regulator";
  margin: 8px 0 0 0 !important;
}

.boximagecont h5 {
  font-size: 18px;
  color: #fff;
  font-family: "Regulator";
  margin-bottom: 0 !important;
}

.boximagecont p {
  font-size: 14px;
  font-family: "optimaregular";
}

.boximagecont .btn:hover {
  color: #fff;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e39684+0,f4c3b8+99 */
  background: #e39684;
  /* Old browsers */
  background: -moz-linear-gradient(top, #e39684 0%, #f4c3b8 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #e39684 0%, #f4c3b8 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #e39684 0%, #f4c3b8 99%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#e39684', endColorstr='#f4c3b8', GradientType=0);
  /* IE6-9 */
}

.boximagebox img {
  width: 80%;
}

.boxtexts {
  text-align: center;
  margin: 0px auto;
  display: block;
  margin-top: 25px !important;
  color: #2f3150;
}

.names h2 {
  color: #fff;
  font-family: "optimaregular";
  font-size: 20px;
}

.names h3 {
  color: #fff;
  font-size: 16px;
  font-family: "Regulator";
  margin-top: 0 !important;
  text-transform: uppercase;
}

.imagesliders img {
  width: 100%;
}

.btncentercenter .btn {
  color: #fff;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e39684+0,f4c3b8+99 */
  background: #e39684;
  /* Old browsers */
  background: -moz-linear-gradient(top, #e39684 0%, #f4c3b8 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #e39684 0%, #f4c3b8 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #e39684 0%, #f4c3b8 99%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#e39684', endColorstr='#f4c3b8', GradientType=0);
  /* IE6-9 */
}

.btnrelatives {
  position: relative;
}

.btncentercenter {
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}

.boxtexts .btn:hover {
  color: #fff;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e39684+0,f4c3b8+99 */
  background: #e39684;
  /* Old browsers */
  background: -moz-linear-gradient(top, #e39684 0%, #f4c3b8 99%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #e39684 0%, #f4c3b8 99%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #e39684 0%, #f4c3b8 99%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#e39684', endColorstr='#f4c3b8', GradientType=0);
  /* IE6-9 */
}

section.mybodypage {
  background: rgb(116, 115, 139);
  background: linear-gradient(
    180deg,
    rgba(116, 115, 139, 1) 0%,
    rgba(148, 147, 163, 1) 100%
  );
}

.half-w {
  width: 50%;
  font-size: 18px;
  color: #fff;
}

.padding-ownc {
  padding-top: 50px;
  padding-left: 50px;
}

.nameupv {
  text-align: center;
}

.names.nameupv h3 {
  font-size: 24px !important;
  margin-bottom: 0;
}

.padding-bots {
  padding-bottom: 50px;
}

.box-loctaion-selectsd p {
  color: #fff;
  font-size: 14px;
}

.sectioninnerbg {
  padding: 80px 0 80px 0;
  background: rgb(193, 193, 201);
  background: linear-gradient(
    180deg,
    rgba(193, 193, 201, 1) 0%,
    rgba(150, 150, 162, 1) 70%
  );
}

.textboxs .inputstyles input {
  padding: 0;
  text-align: left;
  border-bottom: 1px solid #fff;
  color: #fff;
  font-size: 20px !important;
}

.textboxs .inputstyles label {
  margin-bottom: 4px;
}

.textboxs .inputstyles ::-webkit-input-placeholder {
  /* Edge */
  color: #fff !important;
}

.textboxs .inputstyles :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff !important;
}

.textboxs .inputstyles ::placeholder {
  color: #fff !important;
}

.textboxs .inputstyles input:focus-visible {
  outline-offset: 0px !important;
  outline: 0 !important;
  border-bottom: 1px solid #fff !important;
}

.textboxs .inputstyles label {
  font-size: 20px;
  color: #fff;
  font-family: "Regulator";
}

.calendar {
  /* background: #2b4450;
    border-radius: 4px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, .3); */
  perspective: 1000;
  transition: 0.9s;
  transform-style: preserve-3d;
  width: 100%;
}

/* Front - Calendar */

.front {
  transform: rotateY(0deg);
}

.current-date {
  border-bottom: 1px solid rgba(73, 114, 133, 0.6);
  display: flex;
  justify-content: space-between;
  padding: 30px 40px;
}

.current-date h1 {
  color: #dfebed;
  font-size: 1.4em;
  font-weight: 300;
}

.week-days {
  color: #dfebed;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  padding: 0;
}

.days {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.weeks {
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 0 0px;
}

.weeks div {
  display: flex;
  font-size: 1.2em;
  font-weight: 300;
  justify-content: space-between;
  margin-bottom: 8px;
  width: 100%;
}

.last-month {
  opacity: 0.3;
}

span.last-month {
  border: 1px solid #ccd0d6;
}

.weeks span {
  border: 1px solid #fff;
}

.weeks span {
  padding: 12px 3px 2px 10px;
  font-size: 12px;
}

.weeks span.active {
  background: #f78536;
  border-radius: 50%;
}

.weeks span:not(.last-month):hover {
  cursor: pointer;
  /* font-weight: 600; */
}

.event {
  position: relative;
}

.event:after {
  content: "•";
  color: #f78536;
  font-size: 1.4em;
  position: absolute;
  right: -4px;
  top: -4px;
}

/* Back - Event form */

.back {
  height: 100%;
  transform: rotateY(180deg);
}

.back input {
  background: none;
  border: none;
  border-bottom: 1px solid rgba(73, 114, 133, 0.6);
  color: #dfebed;
  font-size: 1.4em;
  font-weight: 300;
  padding: 30px 40px;
  width: 100%;
}

.info {
  color: #dfebed;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: 1.2em;
  padding: 30px 40px;
}

.info div:not(.observations) {
  margin-bottom: 40px;
}

.info span {
  font-weight: 300;
}

.info .date {
  display: flex;
  justify-content: space-between;
}

.info .date p {
  width: 50%;
}

.info .address p {
  width: 100%;
}

.actions {
  bottom: 0;
  border-top: 1px solid rgba(73, 114, 133, 0.6);
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
}

.actions button {
  background: none;
  border: 0;
  color: #fff;
  font-weight: 600;
  letter-spacing: 3px;
  margin: 0;
  padding: 30px 0;
  text-transform: uppercase;
  width: 50%;
}

.actions button:first-of-type {
  border-right: 1px solid rgba(73, 114, 133, 0.6);
}

.actions button:hover {
  background: #497285;
  cursor: pointer;
}

.actions button:active {
  background: #5889a0;
  outline: none;
}

/* Flip animation */

.flip {
  transform: rotateY(180deg);
}

.front,
.back {
  backface-visibility: hidden;
}

ul.week-days {
  list-style: none;
  background: #86849b;
}

ul.week-days li {
  padding: 8px;
  font-size: 12px;
}

.bookdatedark {
  background: #cdcae5;
}

.availdatelight {
}

.avail {
}

.avail span {
  width: 17px;
  height: 17px;
  border: 1px solid #fff;
  background: #cdcae5;
  display: block;
  display: inline-block;
}

.avail {
  display: flex;
  align-items: center;
}

.avail p {
  margin-bottom: 0;
  padding-left: 10px;
  font-family: "Regulator";
  color: #fff;
  font-size: 20px;
}

.avail.notavail span {
  background: transparent;
  border: 1px solid #fff;
}

/*  */

.availsdate {
  background: #cdcae5;
}

.boookeddate {
  background: transparent;
}

.offfdate {
  background: #86849b;
}

/*  */

.offss span {
  background: #86849b;
  border: 1px solid #86849b;
}

.disin {
  display: inline-block;
}

.thirdsec {
  background: rgb(198, 196, 234);
  background: linear-gradient(
    180deg,
    rgba(198, 196, 234, 0.2) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  padding: 50px 0 50px 0;
}

.content-p p {
  font-family: "Regulator";
}

.font19 {
  font-size: 19px !important;
}

hr.hr-c {
  height: 1px;
  width: 100%;
  border: 0 !important;
  background: #86849b;
}

.tabsh h3 {
  font-size: 24px;
  color: #86849b;
}

a.tabbtn {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(226, 226, 234, 0.5) 90%,
    rgb(233 233 233 / 45%) 100%
  );
  display: block;
  padding: 18px;
  text-decoration: none;
  color: #86849b;
  font-size: 24px;
  font-family: "Regulator";
}

.tabs1 {
  margin-bottom: 20px;
}

.btn-tabs a {
  background: rgb(148, 146, 174);
  background: linear-gradient(
    180deg,
    rgba(148, 146, 174, 1) 0%,
    rgba(133, 131, 159, 0.3) 100%
  );
  width: 200px;
  margin: 0px auto;
  display: block;
  padding: 10px;
}

.btn-tabs a:hover {
  color: #fff;
}

.splashtext {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.position-relative {
  position: relative;
}
