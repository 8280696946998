/* #checkinfg{
    background-color:#000!important;
    z-index: 1;
} */
.errormassages {
  display: flex;
  justify-content: center;
  color: #ff3333;
}
.question-spinner {
  display: flex;
  justify-content: center;
}
.rightbtn{
  transition: 0.3s;
  cursor: pointer;
}
.rightbtn:hover{
  transform: scale(1.1);
}
.checkingas {
  position: absolute;
}
.background-bgg {
  background-size: cover;
  height: 100vh;
}

.hidden-display {
  visibility: hidden;
}
.animation-check {
  position: fixed;
  width: 100%;
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .image-rounds {
    padding: 10px 0 0 0px !important;
  }
}
.closebtn {
  cursor: pointer !important;
  z-index: 1;
}
.closebtn a {
  color: #fff !important;
}
.closebtn a:hover {
  color: #e39684 !important;
}
#btn-height {
  line-height: 1.5 !important;
  margin-top: 0px !important;
}
@media only screen and (max-width: 1399px) {
  #btn-height {
    line-height: 0.6 !important;
    margin-top: -8px !important;
  }
}
@media only screen and (max-width: 991px) {
  #btn-height {
    line-height: 0.6 !important;
    margin-top: -15px !important;
  }
}

@media only screen and (max-width: 767px) {
  #btn-height {
    line-height: 1.5 !important;
    margin-top: 0px !important;
  }
}
.image-rounds {
  cursor: pointer;
}

/* .rmsc .dropdown-container:focus-within {
    box-shadow: var(--rmsc-main) 0 0 0 0px!important;
    border-color: transparent!important;
} */

.css-1s2u09g-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent !important;
  border-color: transparent !important;
  border-radius: 0px !important;
  border-style: none !important;
  border-width: 0px !important;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
  box-shadow: none !important;
}
.css-14el2xx-placeholder {
  color: #fff !important;
}
.css-1pahdxg-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent !important;
  border-color: none !important;
  border-radius: 5px !important;
  border-style: solid;
  border-width: 0px !important;
  box-shadow: none !important;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}

.css-1pahdxg-control:hover {
  color: #000;
  background-color: #000;
  background-color: #000;
}
.anchertag {
  cursor: pointer;
}
.anchertag:hover {
  color: #e39684 !important;
}

/* .css-1s2u09g-control {
    position: relative;
    outline: 0;
    color: #000!important;
    background-color: transparent!important;
    border: 1px solid transparent!important;
    border-radius: transparent!important;
    border-color:transparent!important;
    
} */

/* .css-1s2u09g-control :focus-within {
    background-color: transparent!important;
    border: 1px solid #000!important;
    border-radius: transparent!important;
    border-color:transparent!important
} */

.css-6j8wv5-Input {
  background-color: transparent !important;
}
.css-1okebmr-indicatorSeparator {
  background-color: transparent !important;
}
/* .rmsc .gray{
    color: #fff!important;
}

.rmsc .dropdown-container .dropdown-contant{
    background-color: #000!important;
    color: #000!important;
    text-align: right!important;
} */
