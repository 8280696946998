@media screen and (max-width: 1366px) {
    .curve-box-signup::after {
        background-size: 50% 100%;
    }
}

@media screen and (max-width: 1094px) {
    .heading-logo p {
        font-size: 12px;
    }
}

@media screen and (max-width: 991px) {
    .padding-lefts {
        padding-left: 0 !important;
    }
}

@media screen and (max-width:767px) {
    .inputstyles input {
        padding: 5px 0 5px 0;
    }
    .inputstyles ::-webkit-input-placeholder {
        /* Edge */
        font-size: 17px !important;
    }
    .inputstyles :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-size: 17px !important;
    }
    .inputstyles ::placeholder {
        font-size: 17px !important;
    }
    .curve-box-signup.afteroff .lastchilds .headingwithlogo h6 {
        color: #fff;
        font-size: 12px;
        margin-bottom: 10px;
        margin-top: 0 !important;
    }
    .calendar {
        width: 290px;
        margin: 0px auto;
    }
    .half-w {
        width: 100%;
    }
    .boxtexts p {
        color: #fff;
        font-size: 12px;
        padding: 0 50px;
    }
    .boximagecont p {
        color: #fff !important;
    }
    .curve-box-signup.afteroff {
        background: url(../images/mobile-bg-signup.png) !important;
        height: 684px;
        padding: 80px 0 80px 0px;
        margin: 0px auto !important;
        width: 290px !important;
        background-repeat: no-repeat !important;
        background-size: 100% 100% !important;
    }
    .disnonemobile {
        display: none !important;
    }
    .boximagebox img {
        width: 180px;
    }
    .padding-lefts {
        padding-left: 0 !important;
    }
    .curve-box-signup::after {
        display: none;
    }
    .curve-box-signup {
        background: url(../images/mobile-bg-signup.png);
        /* backdrop-filter: blur(7 px) !important; */
        z-index: 0;
        height: 100%;
        width: 317px;
        margin: 0px auto;
        background-size: 100% 100% !important;
        background-repeat: no-repeat;
        padding: 80px 0 80px 0px;
    }
    .formsignup {
        text-align: center;
    }
    .w100 {
        width: 100%;
        text-align: center;
        margin: 0px auto;
    }
    .logo-signups img {
        width: 70%;
    }
    .logo-mobile {
        display: block;
    }
    .formsignup a {
        color: #d9d9d9;
    }
    .image-rounds {
        left: 18px;
        top: 18px;
        text-align: center;
    }
    .mute-rounds {
        left: 18px;
        bottom: 18px;
    }
    .formsignup a {
        font-size: 13px;
    }
    .formsignup h2 {
        font-size: 20px;
    }
    .formsignup input {
        font-size: 20px !important;
    }
    .background-bg {
        background: url(../images/mobile-main-bg.png) !important;
        background-size: 100% 100% !important;
    }
    .box-bottom-href {
        min-height: auto !important;
        text-align: center;
        margin-bottom: 18px;
    }
    .bottomhrefs {
        position: initial !important;
        text-align: center;
    }
    .heading-para p {
        padding-right: 0;
        width: 100%;
    }
    .mt-f {
        margin-top: 10px;
    }
    .heading-para p::after {
        left: 50%;
        transform: translateX(-50%);
        bottom: -10px;
    }
    .mt-f .heading-para p {
        margin-bottom: 25px;
    }
    .loginbtns {
        text-align: center;
    }
    .menulogo {
        text-align: center;
        margin-bottom: 20px;
    }
    .menu-navs {
        text-align: center;
        padding-left: 0;
    }
    .toprightsearch {
        position: absolute;
        top: 70px;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
    }
    .splashtext {
        text-align: center;
    }
    .image-rounds {
        left: 50%;
        top: 18px;
        transform: translateX(-50%);
    }
    .disnoneselect {
        display: none;
    }
    .curve-box-signup.selecttabs {
        padding: 50px 0 50px 0px;
    }
    .headingwithlogo h3 {
        font-size: 15px;
        color: #fff;
        margin: 20px 0 0 0;
        font-family: 'Regulator';
    }
    .select-button a {
        padding: 5px;
        font-size: 17px;
    }
    .nextbtn {
        position: absolute;
        right: -10px;
        width: 34px;
    }
    .rightbtn {
        position: absolute;
        left: -10px;
        width: 34px;
    }
    .mt-40c {
        margin-top: 20px;
    }
    .lastchilds {
        width: 80%;
        margin: 0px auto;
    }
    br {
        display: none !important;
    }
    .padding-ownc {
        padding-top: 50px;
        padding-left: 0;
    }
    .select-button select {
        padding: 5px;
        font-size: 14px;
    }
    .background-bg.huns {
        height: 100vh !important;
    }
}

@media screen and (max-width:576px) {
    .toprightsearch {
        position: initial !important;
        left: 0;
        transform: inherit !important;
        width: 80%;
        margin: 0px auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .lastchilds {
        width: 92%;
    }
    .roundiconsselect::after {
        width: 10px;
        height: 10px;
    }
    .curve-box-signup.selecttabs {
        background: url(../images/mobile-bg-signup.png) !important;
        height: 684px;
    }
    .curve-box-signup {
        width: 290px !important;
    }
    .question h5 {
        font-size: 18px;
    }
    .image-rounds {
        position: initial !important;
        display: block !important;
        transform: initial !important;
        text-align: center;
        padding: 10px 0;
    }
    .mute-rounds {
        position: initial !important;
        display: block !important;
        transform: initial !important;
        text-align: center;
        padding: 20px 0;
    }
    .mute-rounds a {
        text-align: center;
        margin: 0px auto;
        display: block;
    }
    section.background-bg.d-flex {
        display: inherit !important;
    }
    .boxtexts p {
        padding: 0 0 !important;
    }
    .names h2 {
        font-size: 15px;
    }
    .names.nameupv h3 {
        font-size: 15px !important;
    }
    .curve-box-signup.selecttabs {
        background-size: contain !important;
        background-repeat: no-repeat !important;
    }
}

@media screen and (max-width:414px) {
    section.background-bg {
        display: inherit !important;
        background-size: cover !important;
        height: 100%;
    }
}

@media (max-height: 800px) {
    section.background-bg.d-flex {
        display: inherit !important;
        padding: 50px 0;
        background-size: cover !important;
        height: 100% !important;
    }
    .image-rounds {
        position: initial !important;
        display: block !important;
        transform: initial !important;
        padding: 40px 0 0 40px;
    }
    .mute-rounds {
        position: initial !important;
        display: block !important;
        transform: initial !important;
        text-align: center;
        padding: 20px 0 0 20px;
        margin-top: 50px;
    }
    section.background-bg.huns.d-flex.align-items-center {
        height: 100vh !important;
    }
    .position-relative {
        position: relative!important;
        height: 100%;
    }
}