/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 27, 2021 */



@font-face {
    font-family: 'optimanormal';
    src: url('optima-webfont.woff2') format('woff2'),
         url('optima-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'optimabold';
    src: url('optima_b-webfont.woff2') format('woff2'),
         url('optima_b-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'optimaregular';
    src: url('unicode.optima-webfont.woff2') format('woff2'),
         url('unicode.optima-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'Regulator';
    src: url('Regulator-Light.woff2') format('woff2'),
        url('Regulator-Light.woff') format('woff'),
        url('Regulator-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

